import React from "react"

export default function Bio() {
  return (
    <>
      Welcome to my personal blog. Writing that I've done is collected here,
      some is technical, some is business oriented, some is trans related.
    </>
  )
}
